<template>
  <div>
    <div class="text-right">
      <button
        @click="handleDownload()"
        :disabled="downloadLoading"
        class="px-4 py-2 mb-4 bg-gray-50 border rounded-md text-black flex"
      >
        <img src="../../../assets/excel.png" alt="" />
        <span class="mx-3">Download</span>
      </button>
    </div>
    <div class="flex text-lg mb-4">
      <h1 class="pr-4 border-r uppercase font-bold">
        {{ $route.params.reportOn.replace(/_/g, " ") }}
      </h1>
      <h1 class="px-4 border-r">
        From:
        <span class="font-bold">
          {{ $route.params.from | moment("ddd, MMM Do YYYY") }}
        </span>
      </h1>
      <h1 class="px-4">
        To:
        <span class="font-bold">
          {{ $route.params.to | moment("ddd, MMM Do YYYY") }}
        </span>
      </h1>
    </div>

    <table class="min-w-full divide-y divide-gray-200">
      <thead class="bg-gray-50">
        <tr>
          <th
            scope="col"
            class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Date
          </th>
          <th
          scope="col"
          class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
        >
          Account
        </th>
         

          <th
            scope="col"
            class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Created By
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-xs font-medium text-right text-gray-500 uppercase tracking-wider"
          >
          Amount

          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(replenishment, i) in data.reports" :key="i">
          <td class="px-6 py-4 whitespace-nowrap">
            <div class="flex items-center">
              <div class="ml-4">
                <div class="text-sm font-medium text-gray-900">
                  {{ replenishment.formatted_created_at }}
                </div>
              </div>
            </div>
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-center">
            <div class="text-sm text-gray-900">
              {{ replenishment.account_name }}
            </div>
          </td>
         
          <td class="px-6 py-4 whitespace-nowrap text-center">
            <div class="text-sm text-gray-900">
              {{ replenishment.created_by_name }}
            </div>
          </td>
       

        
          <td class="px-6 py-4 whitespace-nowrap text-right">
            <div class="text-sm text-gray-900">
              GMD <b>{{ formatPrice(replenishment.amount) }}</b>
            </div>
          </td>
        </tr>
        <tr>
       
          <td class="px-6 py-4 whitespace-nowrap text-left ">
            <div class="text-sm text-gray-900 font0bold">
           Total Replenishment
            </div>
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-center">
          </td>
      
          <td class="px-6 py-4 whitespace-nowrap text-center">
      
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-right">
            <div class="text-sm text-gray-900">
              GMD <b>{{ formatPrice(data.total_amount) }}</b>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ["data"],
  


  data: () => ({
    downloadLoading: false,
    filename: "",
    autoWidth: true,
    bookType: "xlsx",
  }),

  computed: {
   
  },

  
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    handleDownload() {
  this.downloadLoading = true;
  import("@/vendor/Export2Excel").then((excel) => {
    const tHeader = [
      "Date",
      "Account",
      "Created By",
      "Amount",
      "Total Amount"
    ];

    const filterVal = [
      "formatted_created_at",
      "account_name",
      "created_by_name",
      "amount",
    ];

    const data = this.data.reports.map((replenishment) => {
      const rowData = filterVal.map((key) => {
        if (key === "amount") {
          return `GMD ${this.formatPrice(replenishment[key])}`;
        } else {
          return replenishment[key];
        }
      });
      return rowData;
    });

    const totalAmountRow = Array(tHeader.length).fill("");
    totalAmountRow[tHeader.length - 1] = `GMD ${this.formatPrice(this.data.total_amount)}`;

    excel.export_json_to_excel({
      header: tHeader,
      data,
      filename: this.getFileName(),
      autoWidth: this.autoWidth,
      bookType: this.bookType,
    });
    this.downloadLoading = false;
  });
},


    getFileName() {
      return (
        this.$route.params.reportOn +
        "-" +
        this.$route.params.from +
        "-" +
        this.$route.params.to
      );
    },
  },
};
</script>
